const handleError = (err) => {
    if (err.message.indexOf('insufficient') > -1) {
        alert('You do not have enough ETH for this transaction.')
    }
    else if (err.message.indexOf('not open') > -1) {
        alert('Craft minting is not active yet.');
    }
    else if (err.message.indexOf('enough vapes') > -1) {
        alert ("Not enough vapes to mint your eligible crafts.");
    }
    else if (err.message.indexOf('More than max') > -1) {
        alert('50 max per transaction !');
    } 
    else if (err.message.indexOf('Max supply') > -1) {
    alert('Exceeding supply.');
    }
    else if (err.message.indexOf('Ethereum sent') > -1) {
    alert(
        'Not sending enough Ethereum !',
    );
    } else if (err.message.indexOf('Invalid merkle') > -1) {
    alert(
        'An error has occured, please make sure your address is whitelisted.',
    );
    } else if (err.message.indexOf('Minting more') > -1) {
    alert(
        'You are not allowed to mint this number of crafts at the moment. You have probably minted your eligible crafts already.',
    );
    } else if (err.message.indexOf('already minted with pill') > -1) {
    alert(
        'You have already used one of the pills to mint crafts.'
    );
    }
    else if (err.message.indexOf('Apostle already') > -1) {
        alert(
            'You have already used one of the apostles to mint crafts.'
        );
        }
};
  
export { handleError };