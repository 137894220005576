import React, { useState, useEffect, useContext } from "react";
import { Container, Row } from "react-bootstrap";
import { Web3Context } from "../../data/Web3Context/Web3Context";
import PillGridEntry from "../../components/PillGridEntry/PillGridEntry";
import ApostleGridEntry from "../../components/ApostleGridEntry/ApostleGridEntry";
import { handleError } from "../../helper/errorHandler";
import TransactionModal from "../../components/TransactionModal/TransactionModal";
import UsedCheck from "../../components/UsedCheck/UsedCheck";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "./CraftClaim.css";

function CraftClaim() {
  var claimInterval, vapeBalanceInterval, supplyCheckInterval;

  const {
    web3,
    account,
    pillContract,
    craftContract,
    vapeContract,
    apostleContract,
  } = useContext(Web3Context);

  const [isEligible, setIsEligible] = useState(true);
  const [dataLoaded, setDataLoaded] = useState(false);

  const [pillsFetched, setPillsFetched] = useState(false);
  const [pillsLoading, setPillsLoading] = useState(false);
  const [apostlesFetched, setApostlesFetched] = useState(false);
  const [apostlesLoading, setApostlesLoading] = useState(false);
  const [dirty, setDirty] = useState(false);

  const [pills, setPills] = useState([]);
  const [apostles, setApostles] = useState([]);

  const [selectedPills, setSelectedPills] = useState([]);
  const [selectedApostles, setSelectedApostles] = useState([]);

  const [pairs, setPairs] = useState([]);
  const [claimCount, setClaimCount] = useState(0);
  const [vapeCount, setVapeCount] = useState(0);
  const [eligibleCount, setEligibleCount] = useState(0);
  const [usedCheckOpen, setUsedCheckOpen] = useState(false);

  // Minting properties
  const [isMintLoading, setMintLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [transactionHash, setTransactionHash] = useState(null);
  const [transactionConfirmed, setTransactionConfirmed] = useState(false);

  const handlePillSelectionChange = (id) => {
    const selectedTemp = selectedPills;
    const selectedApostlesTemp = selectedApostles;
    const tempPairs = pairs;

    if (selectedTemp.indexOf(id) > -1) {
      selectedTemp.splice(selectedTemp.indexOf(id), 1);
      const itemData = pillInPairs(id);
      if (itemData) {
        if (itemData.item.apostleId) {
          if (selectedApostlesTemp.indexOf(itemData.item.apostleId) > -1) {
            selectedApostlesTemp.splice(
              selectedApostlesTemp.indexOf(itemData.item.apostleId, 1)
            );
          }
        }
        tempPairs.splice(itemData.idx, 1);
      }
    } else {
      selectedTemp.push(id);
      tempPairs.push({
        pillId: id,
        apostleId: null,
        pairColor: getRandomColor(),
      });
    }

    setSelectedPills(selectedTemp);
    setSelectedApostles(selectedApostlesTemp);
    setPairs(tempPairs);
    setDirty(!dirty);
  };

  const handleApostleSelectionChange = (id) => {
    const selectedTemp = selectedApostles;
    const tempPairs = pairs;

    if (selectedTemp.indexOf(id) > -1) {
    
      const item = apostleInPairs(tempPairs, id);
      if (item) {
        item.apostleId = null;
        selectedTemp.splice(selectedTemp.indexOf(id), 1);
      }
    } else {
      const item = lastValidItemInPair(tempPairs);
      if (item) {
        item.apostleId = id;
        selectedTemp.push(id);
      }
    }

    setSelectedApostles(selectedTemp);
    setPairs(tempPairs);
    setDirty(!dirty);
  };

  const getRandomColor = () => {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const pillInPairs = (id) => {
    const idx = pairs.findIndex((o) => o.pillId === id);
    if (idx > -1) {
      return {
        item: pairs[idx],
        idx,
      };
    } else {
      return null;
    }
  };

  const apostleInPairs = (tempPairs, id) => {
    const idx = tempPairs.findIndex((o) => o.apostleId === id);
    if (idx > -1) {
      return tempPairs[idx];
    } else {
      return null;
    }
  };

  const lastValidItemInPair = (tempPairs) => {
    if (tempPairs.length <= 0) {
      return null;
    }

    if (!tempPairs[tempPairs.length - 1].apostleId) {
      return tempPairs[tempPairs.length - 1];
    } else {
      return null;
    }
  };

  const getPairColorForPill = (pill) => {
    const pillId = Number(pill.name.split(" ")[1]);
    const idx = pairs.findIndex((o) => o.pillId === pillId);
    if (idx > -1) {
      return pairs[idx].pairColor;
    } else {
      return null;
    }
  };

  const getPairColorForApostle = (apostle) => {
    const idx = pairs.findIndex((o) => o.apostleId === apostle.token_id);
    if (idx > -1) {
      return pairs[idx].pairColor;
    } else {
      return null;
    }
  };

  const getValidPairs = () => {
    const valids = [];
    const pillIds = [];
    const apostleIds = [];
    for (let index = 0; index < pairs.length; index++) {
      const element = pairs[index];
      if (element.pillId && element.apostleId) {
        valids.push({
          pillId: element.pillId,
          apostleId: element.apostleId,
        });
        pillIds.push(element.pillId);
        apostleIds.push(element.apostleId);
      } else if (element.pillId && !element.apostleId) {
        valids.push({
          pillId: element.pillId,
          apostleId: -1,
        });
        pillIds.push(element.pillId);
        apostleIds.push(-1);
      }
    }
    return { valids, count: valids.length, pillIds, apostleIds };
  };

  const renderValidPairs = () => {
    const pairs = getValidPairs();
    var string = "";
    pairs.valids.forEach((p) => {
      string += `[${p.pillId}, ${p.apostleId}]`;
    });
    return <p className="CraftClaim_Valids">{string}</p>;
  };

  useEffect(() => {
    if (!pillContract || !craftContract || !vapeContract) {
      return;
    }

    setupAsync();

  

    return () => {
      if (claimInterval) {
        clearInterval(claimInterval);
      }
      if (vapeBalanceInterval) {
        clearInterval(vapeBalanceInterval);
      }
      if (supplyCheckInterval) {
        clearInterval(supplyCheckInterval);
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, pillContract, craftContract, vapeContract]);

  const setupAsync = async () => {
    const eligible = await fetchEligibility();
    if (!eligible) {
      setIsEligible(false);
      setDataLoaded(true);
      return;
    }
    
    setDataLoaded(true);
    fetchClaims();
    fetchVapeBalance();

    claimInterval = setInterval(() => {
      fetchClaims();
    }, 5000);
    vapeBalanceInterval = setInterval(() => {
      fetchVapeBalance();
    }, 5000);
  };

  const fetchPills = async () => {
    setPillsLoading(true);
    var data = [];
    try {
      const fetchRes = await fetch(
        `https://service.byopills.com/pillsForOwner/${account}?nft=${pillContract._address}&chain=eth`
      );
      if (!fetchRes.ok) {
        throw new Error("Something went wrong");
      }
      const jsonData = await fetchRes.json();
      for await (const item of jsonData.result) {
        var parsedMeta = JSON.parse(item.metadata);
        if (parsedMeta) {
          parsedMeta.token_id = Number(item.token_id);
          data.push(parsedMeta);
        }
        else {
          parsedMeta = {};
          parsedMeta.token_id = Number(item.token_id);
          data.push(parsedMeta);
        }
      }

      data = data.sort((a, b) => a.token_id - b.token_id);

      setPills(data);
      setPillsFetched(true);
      setPillsLoading(false);
    } catch (e) {
      console.log (e);
      alert(
        "An error has occured fetching pill data, please refresh and try again."
      );
      setPillsFetched(false);
      setPillsLoading(false);
      return;
    }
  };

  const fetchApostles = async () => {
    setApostlesLoading(true);
    var data = [];
    try {
      const fetchRes = await fetch(
        `https://service.byopills.com/pillsForOwner/${account}?nft=${apostleContract._address}&chain=eth`
      );
      if (!fetchRes.ok) {
        throw new Error("Something went wrong");
      }
      const jsonData = await fetchRes.json();
      for await (const item of jsonData.result) {
        var parsedMeta = JSON.parse(item.metadata);
        if (parsedMeta) {
          parsedMeta.token_id = Number(item.token_id);
          data.push(parsedMeta);
        }
        else {
        
          const feth = await fetch (item.token_uri);
          const fetchJson = await feth.json();
          parsedMeta = fetchJson;
          parsedMeta.token_id = Number(item.token_id);
          data.push(parsedMeta);
        }
      }

      data = data.sort((a, b) => a.token_id - b.token_id);

      setApostles(data);
      setApostlesFetched(true);
      setApostlesLoading(false);
    } catch (e) {
      console.log (e);
      alert(
        "An error has occured fetching pill data, please refresh and try again."
      );
      setApostlesFetched(false);
      setApostlesLoading(false);
      return;
    }
  };

  const renderPills = () => {
    if (pillsLoading) {
      return <p className="PillGrid_Loading">LOADING YOUR PILLS ...</p>;
    } else if (!pillsFetched) {
      return (
        <button
          onClick={() => {
            fetchPills();
          }}
          className="Main_FetchButton"
        >
          {`CLICK TO FETCH PILLS`}
        </button>
      );
    } else {
      if (pills.length <= 0) {
        return <p className="PillGrid_Loading">NO PILLS FOUND.</p>;
      }
      return pills.map((pill, i) => (
        <PillGridEntry
          color={getPairColorForPill(pill)}
          onSelection={handlePillSelectionChange}
          key={i}
          pill={pill}
          i={i}
        />
      ));
    }
  };

  const renderApostles = () => {
    if (apostlesLoading) {
      return <p className="PillGrid_Loading">LOADING YOUR APOSTLES ...</p>;
    } else if (!apostlesFetched) {
      return (
        <button
          onClick={() => {
            fetchApostles();
          }}
          className="Main_FetchButton"
        >
          {`CLICK TO FETCH APOSTLES`}
        </button>
      );
    } else {
      if (apostles.length <= 0) {
        return <p className="PillGrid_Loading">NO APOSTLES FOUND.</p>;
      }
      return apostles.map((apostle, i) => (
        <ApostleGridEntry
          color={getPairColorForApostle(apostle)}
          onSelection={handleApostleSelectionChange}
          key={i}
          apostle={apostle}
          i={i}
        />
      ));
    }
  };

  const fetchEligibility = async () => {
    try {
      const fetchRes = await fetch(
        `https://api.byopills.com/byocraft/snapshot/${account}`
      );
      if (!fetchRes.ok) {
        throw new Error("Something went wrong");
      }
      const data = await fetchRes.json();
      setEligibleCount(data.count);

      if (data.count <= 0) {
        return false;
      } else {
        return true;
      }
    } catch (e) {
      alert(
        "An error has occured fetching eligibility data, please refresh and try again."
      );
      return false;
    }
  };

  const fetchClaims = async () => {
    const { m_craftClaims } = craftContract.methods;
    const claims = await m_craftClaims(account).call();
    setClaimCount(claims);
  };

  const fetchVapeBalance = async () => {
    const { balanceOf } = vapeContract.methods;
    const balance = await balanceOf(account, 0).call();
    setVapeCount(balance);
  };

  const mintCrafts = async () => {
    const validPairData = getValidPairs();
    if (isMintLoading || validPairData.count <= 0) {
      alert("Invalid mint data.");
      return;
    }

    setMintLoading(true);

    const fetchRes = await fetch(
      `https://api.byopills.com/byocraft/merkle/proof/${account}`
    );
    const data = await fetchRes.json();

    if (!data) {
      alert("An unknown error has occured. Please try again in a few.");
      setMintLoading(false);
      return;
    }

    const { idx, maxCount, proof } = data;

    if (proof === "Not Found" || isNaN(maxCount)) {
      alert("This address is not on the whitelist.");
      setMintLoading(false);
      return;
    }

    // Reset
    setTransactionHash(null);
    setTransactionConfirmed(false);

    const { mintCrafts } = craftContract.methods;

    mintCrafts(
      validPairData.pillIds,
      validPairData.apostleIds,
      idx,
      maxCount,
      proof
    ).estimateGas(
      {
        from: account,
      },
      async (err, gas) => {
        if (err) {
          handleError(err);
          setMintLoading(false);
          return;
        }
        mintCrafts(
          validPairData.pillIds,
          validPairData.apostleIds,
          idx,
          maxCount,
          proof
        ).send(
          {
            from: account,
            gas: gas,
          },
          async function (error, txHash) {
            if (error) {
              setMintLoading(false);
              return;
            }

            setTransactionHash(txHash);
            setModalOpen(true);
            setMintLoading(false);

            let transactionReceipt = null;
            while (transactionReceipt == null) {
              transactionReceipt = await web3.eth.getTransactionReceipt(txHash);
              await sleep(1000);
            }

            setTransactionConfirmed(true);
            setTransactionHash(transactionReceipt.transactionHash);
          }
        );
      }
    );
  };

  const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  const renderPage = () => {
    if (!dataLoaded) {
      return <div></div>;
    }

    if (!isEligible) {
      return (
        <p className="CraftClaim_NotEligible">
          Unfortunately, you are not eligible for craft claim.
        </p>
      );
    }

    return (
      <>
        <UsedCheck
          isOpen={usedCheckOpen}
          onClose={() => setUsedCheckOpen(false)}
        />

        <TransactionModal
          open={modalOpen}
          transactionHash={transactionHash}
          transactionConfirmed={transactionConfirmed}
          account={account}
          onClose={() => {
            setModalOpen(false);
          }}
        />

        <p className="CraftClaim_MoreInfo">
          Craft minting is only available for BYOPill and BYOVape holders that
          made the BYOCraft snapshot. <br />
          Please select which pills, and the apostles you would like to use
          below for minting crafts. Each selection makes a pair of pill/apostle
          or pill/no apostle, and that pair will have its own color.
          <br />
          <br /> <b>!! IMPORTANT NOTES !!</b>
          <br />
          <br />{" "}
          <b>
            ⦿ If you select a pill on it's own, it will default to a generic
            craft with no apostle, unless you pair it with an apostle.
          </b>{" "}
          <br />{" "}
          <b>⦿ The apostle you select will link to your last selected pill.</b>{" "}
          <br />
          <b>
            ⦿ The pairs [Pill ID, Apostle ID] will be printed in text above the
            mint button for you to validate (-1 MEANS NO APOSTLE).
          </b>
        </p>

        {pillsFetched && apostlesFetched ? (
          <div className="CraftClaim_Grids">
            <Container fluid className="PillGrid">
              <Row className="PillGrid_Child">{renderPills()}</Row>
            </Container>

            <Container fluid className="PillGrid">
              <Row className="PillGrid_Child">{renderApostles()}</Row>
            </Container>
          </div>
        ) : (
          <div>Loading your data ...</div>
        )}

        {getValidPairs().count > 0 && renderValidPairs()}

        {isMintLoading || pillsLoading || apostlesLoading ? (
          <button className="Main_MintButton">LOADING ...</button>
        ) : getValidPairs().count > 0 ? (
          <button
            onClick={() => mintCrafts()}
            className="Main_MintButton Main_MintButtonActive"
          >
            {`MINT ${getValidPairs().count} CRAFTS`} <br />
            <span className="CraftClaim_MaxNotice">Max 50 Per Tx.</span>
          </button>
        ) : pillsFetched ? (
          <button className="Main_MintButton Main_MintButtonDisabled">
            {`SELECT PILLS/APOSTLES FOR CRAFTS`}
          </button>
        ) : (
          <button
            onClick={() => {
              fetchPills();
              fetchApostles();
            }}
            className="Main_MintButton Main_MintButtonHighlight"
          >
            {`CLICK TO FETCH YOUR DATA`}
          </button>
        )}

        <p
          onClick={() => setUsedCheckOpen(true)}
          className="CraftClaim_UsedCheckButton"
        >
          CHECK USED PILLS/APOSTLES
        </p>

        <div className="CraftClaim_Info">
          <p style={{ color: "#9d00ff" }}>
            Eligible For: <span>{eligibleCount} Craft(s)</span>
          </p>
          <p style={{ color: "#9d00ff", flex: "0.5" }}>
            Vape Balance: <span>{vapeCount} Vape(s)</span>
          </p>
          <p style={{ color: "#9d00ff", flex: "0.5" }}>
            Crafts Claimed: <span>{claimCount} Craft(s)</span>
          </p>
        </div>
      </>
    );
  };

  return <div className="PillGrid_Container">{renderPage()}</div>;
}

export default CraftClaim;
