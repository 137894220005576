import React, { useContext, useState } from 'react';
import { Web3Context } from '../../data/Web3Context/Web3Context';

import './UsedCheck.css';

function UsedCheck({ isOpen, onClose }) {
    const { craftContract } = useContext(Web3Context);
    const [pillId, setPillId] = useState("");
    const [apostleId, setApostleId] = useState("");
    const [status, setStatus] = useState("");
    const [statusColor, setStatusColor] = useState("green");

    if (!isOpen) return null;

    const checkPill = async () => {
        const { pillUsed } = craftContract.methods;
        const res = await pillUsed(pillId).call();
        if (res === true) {
            setStatus ("This pill has been used for a craft.");
            setStatusColor("red");
        }
        else {
            setStatus ("This pill is still available for craft minting.");
            setStatusColor("lightgreen");
        }
    }

    const checkApostle = async () => {
        const { craftForApostle } = craftContract.methods;
        const res = await craftForApostle(apostleId).call();
        if (res >= 0) {
            setStatus ("This apostle has already been used for a craft.");
            setStatusColor("red");
        }
        else {
            setStatus ("This apostle is still available for craft minting.");
            setStatusColor("lightgreen");
        }
    }

    return (
        <div className="UsedCheck">
            <div className="UsedCheck_Container">

                <p className="UsedCheck_Title">CHECK USED PILLS / APOSTLES </p>

                <input value={pillId} onChange={(e) => { setPillId(e.target.value)}} placeholder="Pill ID" />
                <button style={{marginTop: '10px'}} onClick={() => checkPill()} className="LandPage_MintButton">
                    CHECK PILL
                </button>

                <input style={{ marginTop: '20px' }} value={apostleId} onChange={(e) => { setApostleId(e.target.value)}} placeholder="Apostle ID" />
                <button style={{marginTop: '10px'}} onClick={() => checkApostle()} className="LandPage_MintButton">
                    CHECK APOSTLE
                </button>

                <p style={{ color: statusColor }} className="UsedCheck_Result">{status}</p>
            </div>

            <button onClick={onClose} className="UsedCheck_CloseButton">
                    CLOSE
            </button>
        </div>
    )
}

export default UsedCheck
