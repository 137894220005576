import React, { useContext } from "react";
import { Web3Context } from "../../data/Web3Context/Web3Context";

import CraftClaim from "../../components/CraftClaim/CraftClaim";

import "./Main.css";

function Main() {
  const { account, connectMetamask } = useContext(Web3Context);

  return (
    <div className="Main Main_Full">
        <p className="Main_Headline">
        Mint your <span>BYOCraft</span>
      </p>
      {!account ? (
        <button
          onClick={() => connectMetamask()}
          className="Main_ConnectButton"
        >
          {`CONNECT WALLET TO PROCEED`}
        </button>
      ) : (
        <CraftClaim />
      )}

      <div className="Main_Footer">
        <a href="https://etherscan.io/address/0x3e511fe60d5fe09503c5f2a6477a75d0b905b335#code" target="_blank" rel="noreferrer" className="Main_FooterContract">CONTRACT</a>
        <p onClick={() => {connectMetamask()}} className="Main_FooterAddress">{account ? account : "Connect your Wallet"}</p>
        <a href="https://craftalternate.byopills.com" className="Main_FooterAlternate">ALTERNATE MINT</a>
      </div>
    </div>
  );
}

export default Main;
