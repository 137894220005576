import React, { useContext, useState } from "react";
import Loader from "react-loader-spinner";
import { Col } from "react-bootstrap";
import { Web3Context } from "../../data/Web3Context/Web3Context";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "./ApostleGridEntry.css";

function ApostleGridEntry({ apostle, i, onSelection, color }) {

  const { craftContract } = useContext(Web3Context);
  const [selected, setSelected] = useState(false);
  const [loading, setLoading] = useState(false);

  const onApostleSelection = async (id) => {
    if (selected) {
      setSelected(false);
      onSelection(id);
      return;
    }

    setLoading(true);

    const { craftForApostle } = craftContract.methods;
    const res = await craftForApostle(id).call();

    if (res >= 0) {
      alert("This apostle has already been used.");
      setLoading(false);
    } else {
      setSelected(!selected);
      onSelection(id);
      setLoading(false);
    }
  };

  var classNameParsed = "ApostleGrid_Entry";

  if (loading) {
    return (
      <Col xs={3} md={2} lg={2} key={i} className={classNameParsed}>
        <Loader type="Puff" color="white" height={50} width={50} />
      </Col>
    );
  }

  var styleObject = {};
  if (color) {
    styleObject = { border: "2px solid " + color };
  }

  return (
    <Col
      xs={3}
      md={2}
      lg={2}
      key={i}
      style={styleObject}
      className={classNameParsed}
      onClick={() => {
        onApostleSelection(apostle.token_id);
      }}
    >
      {apostle.image && <img alt="pill_image" src={apostle.image} />}
      <p>APOSTLE #{apostle.token_id}</p>
    </Col>
  );
}

export default ApostleGridEntry;
