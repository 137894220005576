import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import Crafts from '../../assets/crafts.png';

import './Snapshot.css';

function Snapshot() {
  const [res, setRes] = useState(null);
  const [addr, setAddr] = useState(null);

  useEffect(() => {}, []);

  const checkAddress = () => {
    fetch(`https://api.byopills.com/byocraft/snapshot/${addr}`)
      .then((data) => data.json())
      .then((parsed) => {
        setRes(parsed);
      });
  };

  const getParsedText = () => {
    if (res.count <= 0) {
      return (
        <div className="Snapshot_Notice_Res">
          <p>
            Your wallet address does not exist in the snapshot.
            <br />
            You will be able to grab a BYOCraft on secondary market once available.
          </p>
        </div>
      );
    } else {
      return (
        <div className="Snapshot_Notice_Res">
          <p className='Snapshot_Details'>Your wallet is eligible for <span>{res.count}</span> {res.count === 1 ? "BYOCraft" : "BYOCrafts"} !</p>
          <p className='Snapshot_Details'>On day of Snapshot, you owned the following</p>
          <p className='Snapshot_Details'>Vapes: <span>{res.vapeCount}</span></p>
          <p className='Snapshot_Details'>Pills: <span>{res.pillCount}</span></p>
        </div>
      );
    }
  };

  return (
    <div className="Snapshot">
     
      <div className="Snapshot_MainSection">
      <img alt="byoplogo" className="Snapshot_Logo" src={Crafts} />
        <div className="Snapshot_Notice">
          Please type in your address below to check your BYOCraft eligibility.
        </div>

        <input
          onChange={(e) => setAddr(e.target.value)}
          className="Snapshot_Input"
          placeholder="Address"
        />

        <button onClick={checkAddress} className="Snapshot_ActionButton">
          CHECK
        </button>

        {res && getParsedText()}
      </div>
    </div>
  );
}

export default withRouter(Snapshot);
